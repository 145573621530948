<template>
  <div class="h-full w-full pt-5">
    <h1 class="text-xl text-left font-extrabold pl-4">
      Directory
    </h1>
    <div class="mx-3 mt-6">
      <Card class="mt-6 p-5">
        <template v-slot:footer>
          <card-footer
            reloadcard
            @reload="reload()"
            @sortType="handleSort($event)"
            @searchResult="handleSearch($event)"
          />
        </template>
      </Card>
    </div>
    <div class="w-full">
      <div class="w-full h-full p-3 mb-8">
        <sTable
          :headers="employeeHeader"
          :items="employees"
          style="width:100%"
          :loading="loading"
          aria-label="employee table"
          class="w-full"
          id="printMe"
          :pagination-list="metaData"
          @page="handlePage($event)"
          @itemsPerPage="handleItemsPerPage($event)"
          page-sync
          has-number
        >
          <template v-slot:item="{ item }">
            <template v-if="item.userId">
              <div class="flex flex-row items-center" @click="viewEmployeeDetails(item.data)">
                <template>
                  <div class="flex w-2 h-2 rounded mr-2">
                    <span class="p-1 rounded"
                      :class="item.data.employmentStatus === 'confirmed' ? 'bg-mediumSeaGreen':
                      item.data.employmentStatus === 'suspended' ? 'bg-carrotOrange':
                      item.data.employmentStatus === 'disengaged' ? 'bg-flame' :
                      item.data.employmentStatus === 're-engaged' ? 'bg-mediumSeaGreen' : 'bg-romanSilver'"
                  />
                  </div>
                </template>
                <template>
                  <div>
                    <img v-if="item.data.photo" :src="item.data.photo" alt="profile pic" class="w-8 h-8 rounded" />
                    <div v-else class="w-8 h-8 rounded text-blueCrayola border text-center font-semibold pt-2">
                      {{ $getInitials(`${item.data.fname} ${item.data.lname}`) }}
                    </div>
                  </div>
                </template>
                <div class="ml-2 text-sm flex flex-col justify-Start">
                  <span class="font-bold cursor-pointer text-jet">
                    {{ item.data.fname }} {{ item.data.lname }}
                  </span>
                  <!-- <div class="text-romanSilver text-xs uppercase font-bold">
                    <span v-if="item.data.userDesignation">
                      {{ item.data.userDesignation.name }}
                    </span>
                    <span v-else>-</span>
                  </div> -->
                </div>
              </div>
            </template>
            <span v-else-if="item.email" class="text-center">{{
              !item.data.email ? "-" : item.data.email
            }}</span>
            <span v-else-if="item.phoneNo" class="text-center">{{
              !item.data.phoneNo ? "-" : item.data.phoneNo
            }}</span>
            <span v-else-if="item.orgFunction" class="text-center">
              {{ !item.data.orgFunction ? "-" : item.data.orgFunction.name }}
            </span>
            <span v-else-if="item.userDesignation" class="text-center">
              {{
                item.data.userDesignation ? item.data.userDesignation.name : "-"
              }}
            </span>
            <span v-else-if="item.userLevel" class="text-center">
              {{ item.data.userLevel ? item.data.userLevel.name : "-" }}
            </span>
            <span v-else-if="item.employmentType" class="text-center">
              {{ item.data.employmentType || "-" }}
            </span>
             <div v-else-if="item.userReportingTo" class="">
              <span v-if="item.data.userReportingTo">
                {{ item.data.userReportingTo.fname }} {{ item.data.userReportingTo.lname }}
              </span>
              <span v-else>-</span>
            </div>
            <div v-else-if="item.resumptionDate" class="mr-4 text-center">
              <span v-if="item.data.resumptionDate">
                {{ $DATEFORMAT(new Date(item.data.resumptionDate), "MMM dd, yyyy") }}
              </span>
              <span v-else>-</span>
            </div>
            <span v-else-if="item.createdAt" class="mr-4 text-center">
              {{ $DATEFORMAT(new Date(item.data.createdAt), "MMMM dd, yyyy") }}
            </span>
            <span v-else-if="item.id">
                <div class="flex  py-1 more h-8 cursor-pointer">
                  <icon
                    icon-name="icon-eye"
                    @click.native="viewEmployeeDetails(item.data)"
                    class-name="text-darkPurple mr-2"
                    size="xsm"
                  />
                </div>
            </span>
          </template>
        </sTable>
      </div>
    </div>
  </div>
</template>

<script>
import STable from "@scelloo/cloudenly-ui/src/components/table";
import {mapState} from "vuex";
import Card from "@/components/Card";
import CardFooter from "@/components/CardFooter";

export default {
  name: "EssDirectory",
  components: {
    STable,
    Card,
    CardFooter
  },
  data() {
    return {
      functions: [],
      itemsPerPage: null,
      numberOfPage: null,
      searchWord: null,
      isActive: null,
      sortType: null,
      functionIds: [],
      designationIds: [],
      userDetails: {},
      openTransferEmployeeModal: false,
      openUpdateEmployeeModal: false,
      openSuspendModal: false,
      openPromoteModal: false,
      openReviseModal: false,
      openDisengageModal: false,
      moreOptions: [
        {
          title: "View",
          icon: "edit",
          color: "blueCrayola",
          slug: "view"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      loading: state => state.employee.loading,
      //   employees: state => state.employee.employees,
      metaData: state => state.employee.metaData
    }),
    employees() {
      return this.$store.state.employee.employees.filter(
        item => item.userId !== this.$AuthUser.id
      );
    },
    employeeHeader() {
      return [
        { title: "Employee", value: "userId" },
        { title: "FUNCTION", value: "orgFunction" },
        { title: "DESIGNATION", value: "userDesignation" },
        { title: "Job Level", value: "userLevel" },
        { title: "Employment type", value: "employmentType" },
        { title: "Reporting To", value: "userReportingTo" },
        { title: "Engagement Date", value: "resumptionDate" },
        { title: "", value: "id", image: true }
      ];
    }
  },
  watch: {},
  methods: {
    queryUpdate() {
      const isActive = this.isActive ? `&isActive=${this.isActive}` : "";
      const search = this.searchWord ? `&search=${this.searchWord}` : "";
      const sort = this.sortType ? `&sort=${this.sortType}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.$store.dispatch(
        "employee/getEmployeesWithQueries",
        `${isActive}${sort}${search}${pageNumber}${itemPage}`
      );
    },
    checkIfPresent(item, itemArray) {
      const itemIndex = itemArray.indexOf(item);
      if (itemIndex >= 0) {
        itemArray.splice(itemIndex, 1);
      } else {
        itemArray.push(item);
      }
      return itemArray;
    },
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },
    handleSearch(searchWord) {
      if (searchWord) {
        this.searchWord = searchWord;
        this.queryUpdate();
      } else {
        this.searchWord = null;
        this.queryUpdate();
      }
    },
    handleSort(sortType) {
      this.sortType = sortType;
      this.queryUpdate();
    },

    viewEmployeeDetails(details) {
      this.$router.push({
        name: "EssDirectioryDetails",
        params: {
          id: details.userId
        },
        query: {
          user: `${details.fname} ${details.lname}`,
          gender: details.gender
        }
      });
    },
    reload() {
      this.$store.dispatch("employee/get_Employees");
    },
    getEmployeeDetails() {
      this.$store.dispatch("employee/getEmployeesEss", "");
    }
  },
  mounted() {
    this.getEmployeeDetails();
  }
};
</script>

<style></style>
